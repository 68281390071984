import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer, ListingCard, NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/eventifyFacebook-1200x630.jpg';
import twitterImage from '../../assets/eventifyTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { searchMapListings, topListings } from "./LandingPage.duck";
import { getListingsById } from "../../ducks/marketplaceData.duck";
import unionWith from "lodash/unionWith";

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, listings, topListings } = props;


  useEffect(() => {
    let mounted = true;
    const searchParams = { perPage: 6 }
    if (mounted) {
      props.onSearchMapListings(searchParams);
      props.fetchTopListings({ perPage: 3 });
    }
    return () => mounted = false;
  }, [])


  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');


  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>

            {/*<li className={css.section}>*/}
            {/*  <div className={css.sectionContentFirstChild}>*/}
            {/*    <SectionLocations intl={intl} />*/}
            {/*  </div>*/}
            {/*</li>*/}

            {!!topListings?.length && (
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <div className={css.title}>
                    <FormattedMessage id="SectionLatestListings.topListings" />
                  </div>
                  <div className={css.topListingsHolder}>
                    {topListings.map(l => (
                      <ListingCard
                        className={css.listingCard}
                        key={l.id.uuid}
                        listing={l}
                        renderSizes={cardRenderSizes}
                      />
                    ))}
                  </div>
                </div>
              </li>
            )}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <div className={css.title}>
                  <FormattedMessage id="SectionLatestListings.title" />
                </div>
                <div className={css.latestListingsHolder}>
                  {listings.map(l => (
                    <ListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                    />
                  ))}
                </div>

                <div className={css.latestButtonHolder}>
                  <NamedLink
                    name="SearchPage"
                    className={css.latestButton}
                  >
                    <FormattedMessage id="SectionLatestListings.showMoreButton" />
                  </NamedLink>
                </div>

              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    searchMapListingIds,
    topListingIds,
  } = state.LandingPage;

  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const topListings = getListingsById(state, topListingIds);

  return {
    listings: mapListings,
    scrollingDisabled: isScrollingDisabled(state),
    topListings
  };
};

export const mapDispatchToProps = dispatch => ({
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  fetchTopListings: searchParams => dispatch(topListings(searchParams)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
